import {createRouter, createWebHistory} from 'vue-router'
import Search from "../components/Search";
import Login from "../components/Login";
import Success from "../components/Success";
/*import Login from "../components/Login";*/


const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/success',
        name: 'Success',
        component: Success,
    },
    {
        path: '/search',
        name: 'Search',
        component: Search
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return {x: 100, y: 100}
    }
})





export default router