<script>

import {getUserInfo} from '../services/LeakbotLookUpService'

export default {
  name: 'Success',
  beforeCreate() {
    if (this.$cookies.get('principleUser') == null) {
      getUserInfo().then(response => {
        this.principleUser = response
        if (this.principleUser != null) {
          this.$cookies.set("principleUser", this.principleUser)
          this.$router.push("/search")
        }
      });
    } else {
      this.principleUser = this.$cookies.get('principleUser')
      this.$router.push("/search")
    }
  }
}
</script>
