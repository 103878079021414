import VueCookies from 'vue3-cookies'
import PulseLoader from './components/PulseLoader'

import {createApp} from 'vue'
import App from './App.vue'
import router from "./router"

let elementApp = createApp(App).use(VueCookies, {
    expireTimes: "360d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "secure"
});

elementApp.component('PulseLoader',PulseLoader)
elementApp.use(router)
elementApp.mount('#app');





