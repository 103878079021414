<script>

import {getMemberById} from '../services/LeakbotLookUpService'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  name: 'DashBoard',

  data() {
    return {
      users: [],
      memberid: '',
      principleUser: null,
      loading: false
    }
  },
  methods: {
    nameKeydown(e) {
      if (e.key === '-') {
        console.log("")
      } else if (/^\W$/.test(e.key)) {
          e.preventDefault();
    }
  },
  validate(value) {
    // if the field is empty
    if (!value) {
      return ' Indtast medlemsnummer';
    }

    if (value.toString().trim().length >= 20) {
      return 'Medlemsnummmer er for langt';
    }

    return true;
  },
  getMemberById() {
    const memberIdFromForm = this.memberid
    if (memberIdFromForm.length <= 0 && memberIdFromForm.length <= 20) {
      alert("Indtast medlemsnummer eller Leakbot rabatkode");
      this.users = []
      this.memberid = 0
    } else {
      let payload = {}

      let number = parseInt(memberIdFromForm)
      if (Number.isInteger(number)) {
        payload = {
          MemberId: number,
          VoucherId: ""
        }
      } else {
        payload = {
          MemberId: 0,
          VoucherId: memberIdFromForm
        }
      }
      this.users = []
      this.loading = true
      getMemberById(payload).then(response => {
        this.users = response
        if (this.users.length <= 0) {
          alert("Kunne ikke finde medlemsnummer eller rabatkode '" + memberIdFromForm);
        }
      }).finally(() => {
        this.loading = false
      });
    }
  },
}/*,
  beforeCreate() {
    if (this.$cookies.get('principleUser') == null) {
      this.$router.push("/login")
    }
  }*/
}
</script>
<template>
  <div class="hello">

    <div class="header  text-center">
      <img alt="Vue logo" src="../static/images/LBlogo.svg" style="max-width: 10%;">
    </div>

    <br>
    <br>
    <br>


    <!--    <div class="auth" v-show="this.$cookies.get('principleUser') != null">-->
    <div class="auth">

      <!--    <div class="auth">-->
      <div class="container" style="margin-top: 40px; margin-bottom: 40px;">

        <div class="row" style="margin: auto; max-width: 500px;">

          <div class="form-group col">

            <input type="text" class="form-control" v-model="memberid" name="memberid"
                   id="memberid"
                   aria-describedby="emailHelp" placeholder="'IDIT medlemsnummer' eller 'Leakbot rabatkode'"
                   @keydown="nameKeydown($event)"
                   @keyup.enter="getMemberById"
                   maxlength="50"/>


          </div>
          <button type="button" @click='getMemberById()' class="btn btn-dark1" style="height: 10%;">Søg</button>

        </div>


        <div class="row" style="margin: auto; max-width: 500px;">
          <p>Søg medlemmer, der har fået tilbudt Leakbot.</p>
        </div>
        <div class="row" style="margin: auto; max-width: 500px;">
          <p>Skal du finde den rabatkode frem, som medlemmet har fået tilsendt, så indtast medlemsnummer.</p>
        </div>
        <div class="row" style="margin: auto; max-width: 500px;">
          <p>Skal du finde et medlemsnummer, kan du indtaste medlemmets rabatkode.</p>
        </div>
        <div class="row" style="margin: auto; max-width: 500px;">
          <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
        </div>

      </div>

      <div class="container" v-show="users.length > 0">

        <br>
        <br>


        <h3>Leakbot Medlemsinformation</h3>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Medlemsnummer</th>
            <th>Rabatkode</th>
            <th>Status</th>

          </tr>
          </thead>
          <tbody>

          <tr v-for="item in users" :key="item.id">
            <td>{{ item.MemberID }}</td>
            <td>{{ item.VoucherID }}</td>
            <td>{{ item.Status }}</td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

    <!-- Footer -->
    <footer class="bg-light text-center text-lg-start">
      <!-- Grid container -->
      <div class="container p-4" style="max-width: 2900px;">
        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="col" style="text-align: left; margin-left: 100px;">
            <p>
              <strong>LB Forsikring A/S</strong><br>
              Amerika Plads 15<br>
              DK - 2100 København Ø<br>
              CVR-nr.: 16 50 08 36
            </p>
          </div>
          <!--Grid column-->
          <div class="col align-self-center">
            <span class="textSupport" style="font-size: 150%">Leakbot support: <a
                href="tel:+4542544644">+45 42 544 644</a></span>
          </div>
          <div class="col align-self-center">
            <span class="textSupport" style="font-size: 150%">email:contact@ihomesnordic.com</span>
          </div>
          <!--Grid column-->
          <div class="col" style="margin-right: 100px;">
            <ul class="list-unstyled" style="float: right;">
              <a href="https://lbforsikring.dk" target="_blank"><img alt="Vue logo" src="../static/images/LBlogo.svg"
                                                                     style="float: right; margin-bottom: 20px;"></a><br>
              <li>
                <a href="https://www.lbforsikring.dk/personoplysninger" class="text-dark">Privatlivspolitik</a>
              </li>
            </ul>
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </div>
      <!-- Grid container -->

      <!-- Copyright -->
      <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
        © 2021 Copyright:
        <a class="text-dark" href="https://lbforsikring.dk">LB Forsikring A/S</a>
      </div>
      <!-- Copyright -->
    </footer>
    <!-- Footer -->

  </div>
</template>

