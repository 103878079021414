<script>

import {getUserInfo} from '../services/LeakbotLookUpService'

export default {
  name: 'Login',

  beforeCreate() {
    if (this.$cookies.get('principleUser') == null) {
      getUserInfo().then(response => {
        this.principleUser = response
        if (this.principleUser != null) {
          this.$cookies.set("principleUser", this.principleUser)
          console.log("User info set in cookies, redirecting")
          this.$router.push("/search")
        }
      });

      if (this.$cookies.get('principleUser') == null) {
        window.location.href = "/.auth/login/aad?post_login_redirect_uri=https://leakbot-koder.lb.dk/success"
      }
    } else {
      this.$router.push("/search")
    }
  }
}
</script>
