export async function getMemberById(data) {
    const response = await fetch('https://lbsbxprodlbetaapimgmt.azure-api.net/waterleaks/search', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Ocp-Apim-Subscription-Key": '76180a71afc14bcf98262de71c564846',
            'Access-Control-Allow-Origin':'http://leakbot-koder.lb.dk/',
            'Access-Control-Allow-Methods': 'POST, OPTIONS',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Headers': '*'
        },
        body: JSON.stringify(data)

    })
    return await response.json();
}

export async function getUserInfo() {
    const response = await fetch("/.auth/me");
    const payload = await response.json();
    const {clientPrincipal} = payload;
    return clientPrincipal;
}